import React from 'react';
import { decode } from 'he';
import { Nullish } from '../typings/common';
import { ResourceCardModel } from '../components/ResourceCard/ResourceCard';

export function isBrowser(): boolean {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

export function trimTrailingSlash(url: string): string {
  return url.endsWith('/') && url !== '/' ? url.slice(0, -1) : url;
}

export function prepareNavUrl(url: string) {
  return trimTrailingSlash(
    url
      .replace('https://dev.whiparound.com', '')
      .replace('https://whiparound.com', '')
      .replace('https://wp.whiparound.com', '')
      .replace('https://wp.dev.whiparound.com', '')
      .replace('https://whiparound.netlify.com', '')
      .replace('https://whiparound.netlify.app', '')
  );
}

function getImageUrlFromContent(contentString: string): string | null {
  if (isBrowser()) {
    const contentElement = document.createElement('div');
    contentElement.innerHTML = contentString;

    const foundImageNode = contentElement.querySelector('img');
    return foundImageNode ? foundImageNode.getAttribute('src') : null;
  }

  return null;
}

export function convertLineFeeds(string: string): Array<React.ReactNode> {
  const parts = string.split('\n');

  const result: Array<React.ReactNode> = [];

  parts.forEach((part, index, array) => {
    result.push(part);

    if (index !== array.length - 1) {
      result.push(React.createElement('br'));
    }
  });

  return result;
}

export function getCookie(name: string) {
  if (typeof document === 'undefined') return null;
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
        '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : null;
}

export function isNumericString(string: string): boolean {
  return /^\d*$/.test(string);
}

export function integerChecker(string: string, previousValue: string): string {
  return isNumericString(string) ? string : previousValue;
}

export function getLocation(): Location | null {
  return isBrowser() ? window.location : null;
}

export function getOrigin(fallback = ''): string {
  const location = getLocation();
  return location ? location.origin : fallback;
}

function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function decodeHtmlEntities(html: Nullish<string>): string {
  if (isString(html)) {
    return decode(html);
  }

  return '';
}

export function formatUrl(url: string): string {
  const gclid = getCookie('captureGclid');
  if (!gclid) {
    return url;
  }

  if (!url.startsWith('https://api.whip-around.com')) {
    return url;
  }

  if (url.includes('?')) {
    url = url + '&gclid=' + gclid;
  } else {
    url = url + '?gclid=' + gclid;
  }

  return url;
}

const a = decodeHtmlEntities(null);
